import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../state/actions/auth';
//import './styles.css'
import { Navigate } from 'react-router-dom';
import paths from '../Router/paths';

function LoginPage() {
    const dispatch = useDispatch()
    const { error, isAuth, loading } = useSelector(
        (state) => ({
          error: state.auth.error,
          isAuth: !!state.auth.userData.id,
          loading: state.auth.loading,
        }),
        shallowEqual
      );
    const redirect = isAuth && <Navigate to={paths.ROOT} />;

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'hidden',
      };

    function handleSignIn(event) {
        event.preventDefault(); // Prevent form submission to perform custom handling

        // Retrieve email and password values
        let email = document.getElementById("Login-form-Email-Address").value;
        let password = document.getElementById("field").value;

        // Check if password meets the minimum length requirement
        if (password.length < 8) {
            alert("Password must be at least 8 characters long.");
            return;
        }

        // Call the handleSignIn function with the email and password values
        dispatch(signIn(email, password));
    }

   
    
    return (
        <div style={containerStyle}>
            <div class="section secondary-background wf-section">
                {redirect}
                <div class="container w-container">
                    <img src="logo1330.png" loading="lazy" alt="logo" class="centered-logo" />
                    <div class="centered-form simple-shadow-small w-form">
                        <div class="form-heading">Login</div>
                        <form id="wf-form-Login-form" name="wf-form-Login-form" data-name="Login form" onSubmit={(event) => handleSignIn(event)}>
                            <label for="Login-form-Email-Address">Email Address</label>
                            <input type="email" class="input w-input" autofocus="true" maxlength="256" name="Login-form-Email-Address" data-name="Login form Email Address" placeholder="Enter Your Email Address" id="Login-form-Email-Address" required="" />
                            <label for="Password-3">Password</label>
                            <input type="password" class="input w-input" maxlength="256" name="Password-2" data-name="Password 2" placeholder="Enter your Password" id="field" required="" />
                            <input type="submit" value="Submit" data-wait="Please wait..." class="button2 full-width w-button" />
                        </form>
                        <div class="form-success w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                    <a href="index.html#" class="centered-text-link">Forgot your password?</a>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;